import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyles from '../../styles/globalStyles';
import Normalize from '../../styles/normalize';
import Variables from '../../styles/variables';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { ease } from '../../utils/easing';
import GradientCanvas from './canvas';
import LayoutProvider from './layoutProvider';
import LoadingAnim from '../atoms/loading';

const Layout = ({ children, location }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => setInitialLoad(false), 3000);
  }, [setInitialLoad]);

  return (
    <LayoutProvider>
      {/**
       * Styles
       */}
      <Normalize />
      <GlobalStyles />
      <Variables />
      {/**
       * Content
       */}
      {initialLoad && <LoadingAnim loading={initialLoad} />}
      <Header path={location.pathname} />
      <GradientCanvas path={location.pathname} />
      {location.pathname.includes('/news') ? (
        <>
          <main>{children}</main>
          <Footer path={location.pathname} />
        </>
      ) : (
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.main
            key={location && `key${location.pathname}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.2,
              ease: ease.inSnap,
            }}
          >
            {children}
          </motion.main>
          <Footer path={location.pathname} />
        </AnimatePresence>
      )}
    </LayoutProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default Layout;
