const size = {
  small: '425px',
  medium: '900px',
  large: '1025px',
  xlarge: '1800px',
};

export const media = {
  S: `(min-width: ${size.small})`,
  M: `(min-width: ${size.medium})`,
  L: `(min-width: ${size.large})`,
  XL: `(min-width: ${size.xlarge})`,
};
