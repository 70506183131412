import { createGlobalStyle } from 'styled-components';

const Variables = createGlobalStyle`

  :root {
    /* Colors */
    --red: #FF4628;
    --red-mid: #FFDCC8;
    --red-light: #FFF1E9;
    --white: #FFFFFF;
    --black: #000000;
    --black-light: #B4B0B0;

    --c-text: var(--black);
    --c-bg: var(--red);

    /* Spacing */
    --sp-0: 1rem;
    --sp-1: 1.5rem;
    --sp-2: 2rem;
    --sp-3: 3rem;
    --sp-5: 5rem;
    --sp-8: 6rem;
    --sp-13: 13rem;
    --sp-21: 21rem;
    --sp-34: 34rem;

    /* Variable Spacing */
    /* Clamp Fallback */ --sp-1-3: var(--sp-2);
    --sp-1-3: clamp(var(--sp-1), 2.5vw, var(--sp-3));
    /* Clamp Fallback */ --sp-3-5: calc(var(--sp-5) - var(--sp-1));
    --sp-3-5: clamp(var(--sp-3), 3vw, var(--sp-5));
    /* Clamp Fallback */ --sp-1-8: var(--sp-5);
    --sp-1-8: clamp(var(--sp-1), 4vw, var(--sp-8));
    /* Clamp Fallback */ --sp-3-8: var(--sp-5);
    --sp-3-8: clamp(var(--sp-3), 4vw, var(--sp-8));
    /* Clamp Fallback */ --sp-8-13: calc(var(--sp-13) - var(--sp-3));
    --sp-8-13: clamp(var(--sp-8), 5vw, var(--sp-13));
    /* Clamp Fallback */ --sp-8-13: calc(var(--sp-21) - var(--sp-5));
    --sp-13-21: clamp(var(--sp-13), 6vw, var(--sp-21));

    /* Fonts */   
    --f-sans: 'Poppins', sans-serif;

    /* Font Sizes */
    /* Clamp Fallback */ --fs-hl: calc(36px + (48 - 36) * (100vw - 375px) / (2560 - 375));
    --fs-hl: clamp(3.6rem, 5vw, 4.8rem);
    /* Clamp Fallback */ --fs-subhl: calc(24px + (32 - 24) * (100vw - 375px) / (2560 - 375));
    --fs-subhl: clamp(2.4rem, 4vw, 3.2rem);
    /* Clamp Fallback */ --fs-copy: calc(16px + (19 - 16) * (100vw - 375px) / (2560 - 375));
    --fs-copy: clamp(1.7rem, 3vw, 2rem);
    /* Clamp Fallback */ --fs-logo: calc(16px + (20 - 16) * (100vw - 375px) / (2560 - 375));
    --fs-logo: clamp(1.6rem, 3vw, 2rem);
    /* Clamp Fallback */ --fs-small: calc(12px + (14 - 12) * (100vw - 375px) / (2560 - 375));
    --fs-small: clamp(1.2rem, 2vw, 1.4rem);
 
    /* Font Settings */
    --ls: 0.01rem;
    --lh: 1.5;
    --lh-hl: 1.25;
    --lh-copy: 1;

    /* Layout */
    --g-cols: 12;

    --header-height: 60px;
    --header-height-at-m: 80px;
    --sidebar-width: var(--sp-1-8);
    --sidebar-width-at-m: 85px;
  }
`;

export default Variables;
