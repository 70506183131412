import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import Nav from '../atoms/nav';
import MenuBtn from '../atoms/menuBtn';
import { media } from '../../utils/media-queries';
import Logo from '../../assets/svgs/DraupnirBio_Logo_White_RGB.svg';
import { useWindowSize } from '../../hooks/useWindowSize';

const Header = ({ path }) => {
  const isHome = path === '/'
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => setIsNavOpen(!isNavOpen);

  const [logoColor, setLogoColor] = useState('');

  const { scrollYProgress } = useViewportScroll();
  const logoOpacity = useTransform(scrollYProgress, [0, 0.1], [1, 0]);
  const sideLogoOpacity = useTransform(scrollYProgress, [0.075, 0.175], [0, 1]);

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (path.includes('/news') || path.includes('/interview') || path.includes('/careers')) {
      setLogoColor('');
    } else if (!isNavOpen) {
      setLogoColor('');
    } else if (isNavOpen) {
      setTimeout(() => {
        setLogoColor('');
      }, 350);
    } else {
      setLogoColor('');
    }
  }, [path, logoColor, isNavOpen]);

  useEffect(() => {
    setIsMobile(size.width < 900);
  }, [size]);

  return (
    <StyledHeader className={`grid ${logoColor} ${(path.includes('/news') || path.includes('/interview')) ? 'news' : ''}`}>
      {!isHome &&
        <button
          type="button"
          aria-label="Logo"
          onClick={() => setIsNavOpen(false)}
          className={`logo ${logoColor}`}
        >
          <motion.div style={{ opacity: logoOpacity }}>
            <Link to="/">
              <Logo />
            </Link>
          </motion.div>
        </button>
      }
      {!isMobile &&
        <div className={'sidebar'}>
          <button
            type="button"
            aria-label="Side Logo"
            onClick={() => setIsNavOpen(false)}
            className={`logo-side ${logoColor}`}
          >
            <motion.div style={{ opacity: sideLogoOpacity }}>
              <Link to="/">
                <Logo />
              </Link>
            </motion.div>
          </button>
        </div>
      }
      <MenuBtn
        triggerAnim={isNavOpen}
        onClick={toggleNav}
        path={path}
        navOpen={isNavOpen}
        className={'menu-btn'}
      />
      <Nav triggerAnim={isNavOpen} onClick={toggleNav} path={path} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 210;
  width: 100%;
  position: fixed;
  height: var(--header-height);
  pointer-events: none;

  @media ${media.M} {
    height: var(--header-height-at-m);
    display:grid;

    &.news {
      display:flex;
    }
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    align-items: flex-end; 
    justify-content: center;
    width: var(--sidebar-width);
    z-index: 201;

    @media ${media.M} {
      width: var(--sidebar-width-at-m);
    }
  }

  .menu-btn {
    pointer-events: auto;
    position: absolute;
    z-index: 201;
    top: 50%;
    right: var(--sp-1);
    transform: translateY(-50%);

    @media ${media.M} {
      width: var(--sidebar-width-at-m);
      left: 0;
      right: auto;
    }
  }

  .logo {
    pointer-events: auto;
    @media ${media.M} {
      grid-column: 6 / 13;
      margin-left:3.5rem;
    }

    svg {
      display: block;
      width: 112px;
      height: 16px;
      
      @media ${media.M} {
        width: 126px;
        height: 18px;
      }
    }

    &.news {
      g {
        transition: 0.3s;
        fill: var(--white);
      }
      margin:0 !important;
    }
  }

  @media ${media.XL} {
    .logo {
      grid-column: 6 / 13;
      margin-left:1.8rem;
    }
  }

  .logo-side {
    left: 0;
    white-space: nowrap;
    z-index: 200;
    pointer-events: auto;
    transform: rotate(-90deg) translateX(calc(126px / 2)) translateX(64px);
    
    svg {
      display: block;
      width: 126px;
      height: 18px;
    }

    &.news {
      g {
        transition: 0.3s;
        fill: var(--white);
      }
    }
  }
`;

Header.propTypes = {
  path: PropTypes.string,
};

export default Header;
