import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { ease } from '../../utils/easing';
import { media } from '../../utils/media-queries';
import { useWindowSize } from '../../hooks/useWindowSize';
import Logo from '../../assets/svgs/DraupnirBio_Logo_White_RGB.svg';

const Nav = ({ triggerAnim, onClick, path }) => {
  const { social } = useStaticQuery(graphql`
    query Social {
      social: allSanityFooter {
        nodes {
          social {
            url
            title
          }
        }
      }
    }
  `);

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 900);
  }, [size]);

  const navVariants = {
    hidden: {
      width: '100%',
      y: isMobile ? '-100vh' : '-100vh',
    },
    show: {
      width: '100%',
      y: '0vh',
    },
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const [logo, setLogo] = useState(true);

  useEffect(() => {
    if (path === '/') {
      setLogo(false);
    } else {
      setLogo(true);
    }
  }, [path]);

  return (
    <StyledNav
      variants={navVariants}
      initial="hidden"
      animate={triggerAnim ? 'show' : 'hidden'}
      transition={{ duration: isMobile ? 0.5 : 0.7, ease: ease.inOutSnap }}
      onClick={onClick}
      style={{ pointerEvents: triggerAnim ? 'all' : 'none' }}
    >
      {logo && (
        <div className="logo">
          <button type="button" aria-label="Logo">
            <Link to="/">
              <Logo />
            </Link>
          </button>
        </div>
      )}
      <nav>
        <motion.ul
          className="hl nav"
          variants={contentVariants}
          initial="hidden"
          animate={triggerAnim ? 'show' : 'hidden'}
          transition={{ delay: triggerAnim ? 0.35 : 0.05 }}
        >
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/research">Research</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
        </motion.ul>
        <motion.div
          className="social"
          initial={{ opacity: 0 }}
          animate={{ opacity: triggerAnim ? 1 : 0 }}
          transition={{ duration: 0.4, ease: ease.inSmooth }}
        >
          <p>Follow us:</p>
          <ul>
            {social.nodes[0].social.map((socialItem, i) => (
              <li key={i}>
                <a
                  className="underline"
                  href={socialItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialItem.title}
                </a>
              </li>
            ))}
          </ul>
        </motion.div>
      </nav>
    </StyledNav>
  );
};

const StyledNav = styled(motion.div)`
  width: 100%;
  height:100%;
  position: fixed;
  right: 0;
  top: 0;
  color: var(--white);
  z-index: 200;

  .logo {
    width: 100%;
    position: fixed;
    left: 0;
    top: 21px;
    white-space: nowrap;
    z-index: 202;
    display: flex;
    justify-content: center;
    align-items: center;

    // button {
    //   width: 32.5%;
    // }
  }

  nav {
    background: var(--red);
    height: 100%;
    padding-top: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: var(--sp-1);
    align-self: flex-end;
    font-size: 4.5rem;

    a {
      transition: opacity 0.3s;
    }

    &:hover a:not(:hover) {
      opacity: 0.3;
    }
  }

  .social {
    display: flex;
    font-size: var(--fs-small);
    justify-content: flex-end;
    padding-bottom: var(--sp-8);
    padding-right: var(--sp-1-8);

    p {
      opacity: 0.75;
      padding-right: 4rem;
    }

    ul {
      text-align: right;
    }
  }

  @media ${media.M} {
    top: 0;
    left: 0;

    .logo {
      display: none;
    }

    nav {
      padding-top: 8%;
      height: 100%;
    }

    .nav {
      padding-right: var(--sp-1-8);
      font-size: 8rem;
    }

    .social {
      padding-bottom: var(--sp-5);
    }
  }
`;

Nav.propTypes = {
  triggerAnim: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Nav;
