import React, { useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import fragment from '../../shaders/fragment.glsl';
import vertex from '../../shaders/vertex.glsl';

const Gradient = ({ path }) => {
  const clock = new THREE.Clock();
  const ref = useRef();

  const uniforms = useMemo(
    () => ({
      u_time: { value: 0.0 },
      u_red: { value: new THREE.Color('rgb(255, 70, 40)') },
      u_redmid: { value: new THREE.Color('#FFDCC8') },
      u_white: { value: new THREE.Color('#FFF1E9') },
    }),
    []
  );

  const [grayRCount, setGrayRCount] = React.useState(255);
  const [grayGCount, setGrayGCount] = React.useState(70);
  const [grayBCount, setGrayBCount] = React.useState(40);

  const [redRCount, setRedRCount] = React.useState(200);
  const [redGCount, setRedGCount] = React.useState(194);
  const [redBCount, setRedBCount] = React.useState(194);

  useFrame(() => {
    clock.getElapsedTime();
    uniforms.u_time.value = clock.oldTime / 1000;

    if (path.includes('/news') || path.includes('/careers')) {
      setRedRCount(200);
      setRedGCount(194);
      setRedBCount(194);

      if (!(grayRCount <= 200)) {
        setGrayRCount((prevCount) => prevCount - 4);
      }

      if (!(grayGCount >= 194)) {
        setGrayGCount((prevCount) => prevCount + 3);
      }

      if (!(grayBCount >= 194)) {
        setGrayBCount((prevCount) => prevCount + 4);
      }

      uniforms.u_red.value = new THREE.Color(
        `rgb(${grayRCount}, ${grayGCount}, ${grayBCount})`
      );
    } else {
      setGrayRCount(255);
      setGrayGCount(70);
      setGrayBCount(40);

      if (!(redRCount >= 255)) {
        setRedRCount((prevCount) => prevCount + 4);
      }

      if (!(redGCount <= 70)) {
        setRedGCount((prevCount) => prevCount - 3);
      }

      if (!(redBCount <= 40)) {
        setRedBCount((prevCount) => prevCount - 4);
      }

      uniforms.u_red.value = new THREE.Color(
        `rgb(${redRCount}, ${redGCount}, ${redBCount})`
      );
    }
  });

  const { viewport } = useThree();

  return (
    <mesh ref={ref} scale={[viewport.width, viewport.height, 1]}>
      <planeBufferGeometry />
      <meshNormalMaterial />
      <shaderMaterial
        side={THREE.DoubleSide}
        uniforms={uniforms}
        fragmentShader={fragment}
        vertexShader={vertex}
      />
    </mesh>
  );
};

Gradient.propTypes = {
  path: PropTypes.string,
};

export default Gradient;
