import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { ease } from '../../utils/easing';
import { media } from '../../utils/media-queries';

const Loading = ({
  bg = 'var(--white)',
  color = 'var(--red)',
  transition,
  triggerAnim,
}) => (
  <StyledLoading
    style={{ background: bg }}
    initial={{ clipPath: 'inset(0 0 0% 0)' }}
    animate={{
      clipPath: !triggerAnim ? 'inset(0 0 100% 0)' : 'inset(0 0 0% 0)',
    }}
    transition={transition}
  >
    <p className="num" style={{ color }}>
      <CountUp start={0} end={100} duration={2} suffix="%" />
    </p>
  </StyledLoading>
);

const StyledLoading = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;

  .num {
    font-size: 9rem;
    position: absolute;
    bottom: 10rem;
    right: 5rem;
  }

  @media ${media.M} {
    .num {
      font-size: 18rem;
      right: 10rem;
    }
  }
`;

Loading.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  transition: PropTypes.object,
  triggerAnim: PropTypes.bool,
};

const LoadingAnim = ({ loading = true }) => {
  const ref = useRef(null);
  const [triggerAnim, setTriggerAnim] = useState(true);

  useEffect(() => {
    if (loading) {
      disableBodyScroll(ref.current);
    } else if (!loading) {
      enableBodyScroll(ref.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [loading]);

  useEffect(() => {
    setTimeout(() => setTriggerAnim(false), 0);
  }, []);

  return (
    <section ref={ref}>
      <Loading
        bg="var(--red)"
        color="var(--white)"
        triggerAnim={triggerAnim}
        transition={{ duration: 1, delay: 2, ease: ease.inOutSmooth }}
      />
      <Loading
        triggerAnim={triggerAnim}
        transition={{ duration: 2, ease: ease.inOutSnap }}
      />
    </section>
  );
};

LoadingAnim.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingAnim;
