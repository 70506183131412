import { createGlobalStyle } from 'styled-components';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--f-sans);
    font-weight: 400;
    color: var(--c-text);
    background: var(--c-bg);
    font-size: 10px;
  }

  body {
    font-size: var(--fs-copy);
    line-height: var(--lh);
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-x: none;
  }

  /* Typography */

  .hl {
    font-weight: 300;
    font-size: var(--fs-hl);
    line-height: var(--lh-hl);
    color: var(--white);
  }
  
  /* Images */

  .gatsby-image-wrapper {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  /* Scrollbar */

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* Layout */

  .sp-outer {
    margin: var(--sp-1-8);
  }

  .sp-inner {
    padding: var(--sp-1-8);
  }

  .sp-removed {
    margin-right: calc(var(--sp-1-8) * -1);
    margin-bottom: calc(var(--sp-1-8) * -1);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(var(--g-cols), 1fr);
    gap: var(--sp-1-3);

    * {
      grid-column: 1 / calc(var(--g-cols) + 1);
      gap: 0;
    }
  }
  
  /* Animations */

  .underline {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2.5px;
      height: 1px;
      width: 100%;
      background: var(--white);
      transition: 0.3s;
    }

    &:hover:after {
      width: 33%;
    }
  }

  .underline-gray {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2.5px;
      height: 1px;
      width: 100%;
      background: var(--black-light);
      transition: 0.3s;
    }

    &:hover:after {
      width: 33%;
    }
  }

  @media ${media.XL} {
    html {
      font-size: 12px;
    }
  }

  .richtext {
    > p {
      margin:2rem 0;
    }
  }
`;

export default GlobalStyles;
