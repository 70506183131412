import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { media } from '../../utils/media-queries';
import { useWindowSize } from '../../hooks/useWindowSize';

const MenuBtn = ({ path, onClick, triggerAnim = false, navOpen }) => {
  const duration = 0.15;
  const space = 2.5;

  const line1 = {
    closed: {
      rotate: 0,
      y: -space,
      transition: { duration },
    },
    open: {
      rotate: 45,
      y: 0,
      transition: { duration },
    },
  };

  const line2 = {
    closed: {
      rotate: 0,
      y: space,
      transition: { duration },
    },
    open: {
      rotate: -45,
      y: 0,
      transition: { duration },
    },
  };

  const [colors, setColors] = useState({
    bg: 'var(--white)',
    lines: 'var(--red)',
  });

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 900);
  }, [size]);

  useEffect(() => {
    if (path.includes('news') || path === '/careers') {
      if (navOpen && isMobile) {
        setColors({
          bg: 'var(--white)',
          lines: 'var(--red)',
        });
      } else {
        setColors({
          bg: 'var(--white)',
          lines: 'var(--red)',
        });
      }
    } else {
      setColors({
        bg: 'var(--white)',
        lines: 'var(--red)',
      });
    }
  }, [path, navOpen]);

  return (
    <StyledMenuBtn
      onClick={onClick}
      className="menu-btn"
      type="button"
      aria-label="Menu Button"
    >
      <motion.div
        className="wrapper"
        style={{ background: colors.bg }}
      >
        <motion.div
          style={{ background: colors.lines }}
          className="line"
          variants={line1}
          initial="closed"
          animate={triggerAnim ? 'open' : 'closed'}
        />
        <motion.div
          style={{ background: colors.lines }}
          className="line"
          variants={line2}
          initial="closed"
          animate={triggerAnim ? 'open' : 'closed'}
        />
      </motion.div>
    </StyledMenuBtn>
  );
};

const StyledMenuBtn = styled.button`
  display: flex;
  align-items: center;

  @media ${media.M} {
    justify-content: center;
  }

  .wrapper {
    height: 29px;
    width: 29px;
    background: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid var(--red-mid);

    @media ${media.M} {
      border:none;
      width: 39px;
      height: 39px;
    }
  }

  .line {
    width: 13px;
    height: 1px;
    background: var(--red);
    position: absolute;

    @media ${media.M} {
      height: 1.2px;
      width: 15px;
    }
  }

`;

MenuBtn.propTypes = {
  onClick: PropTypes.func,
  triggerAnim: PropTypes.bool,
  path: PropTypes.string,
  navOpen: PropTypes.bool,
};

export default MenuBtn;
