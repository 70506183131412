import React from 'react';
import { Canvas } from '@react-three/fiber';
import PropTypes from 'prop-types';
import Gradient from '../molecules/gradient';

const GradientCanvas = ({ path }) => (
  <Canvas
    style={{
      position: 'fixed',
      zIndex: -999,
      pointerEvents: 'none',
    }}
  >
    <ambientLight />
    <pointLight position={[10, 10, 10]} />
    <Gradient path={path} />
  </Canvas>
);

GradientCanvas.propTypes = {
  path: PropTypes.string,
};

export default GradientCanvas;
