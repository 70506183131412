import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { media } from '../../utils/media-queries';
import RichText from '../atoms/richtext';
import Logo from '../../assets/svgs/DraupnirBio_Logo_White_RGB.svg';
import { ease } from '../../utils/easing';
import { useWindowSize } from '../../hooks/useWindowSize';

const Footer = ({ path }) => {
  const { footer } = useStaticQuery(graphql`
    query Footer {
      footer: allSanityFooter {
        nodes {
          headline
          email
          _rawAddress
          social {
            title
            url
          }
        }
      }
    }
  `);

  const data = footer.nodes[0];

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 900);
  }, [size]);

  const [year, setYear] = useState();
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const [color, setColor] = useState();
  const [margin, setMargin] = useState();
  const [marginSec, setMarginSec] = useState();

  const { ref, inView } = useInView({ treshold: 0, triggerOnce: true });

  useEffect(() => {
    if (path === '/' || path.includes('news')) {
      setColor('var(--white)');
    } else {
      setColor('');
    }

    if (path === '/') {
      if (isMobile) {
        setMarginSec('var(--sidebar-width)');
      } else {
        setMarginSec('var(--sidebar-width-at-m)');
      }
    } else if (isMobile && !path.includes('news')) {
      if (isMobile) {
        setMarginSec('var(--sidebar-width)');
      } else {
        setMarginSec('var(--sidebar-width-at-m)');
      }
    } else if (isMobile && path.includes('news')) {
      setMarginSec('');
    } else {
      setMarginSec('');
    }

    if (
      path === '/' ||
      path.includes('about') ||
      path.includes('research') ||
      path.includes('careers')
    ) {
      if (isMobile) {
        setMargin('var(--sidebar-width)');
      } else {
        setMargin('var(--sidebar-width-at-m)');
      }
    } else {
      setMargin('');
    }
  }, [path, isMobile]);

  return (
    <StyledFooter
      className="grid"
      style={{
        background:
          (path === '/' && 'transparent') ||
          (path === '/news' && 'transparent'),
        marginLeft: path === '/' && 0,
        display: path.includes('/news/') && 'none',
      }}
      color={color}
    >
      <div
        className="disclaimer"
        style={{
          color,
          marginLeft: margin,
        }}
        ref={ref}
      >
        <div className="terms">
          <p className="privacy">
            <Link
              to="/privacypolicy"
              className={`${color ? 'underline' : 'underline-gray'}`}
            >
              Privacy Policy
            </Link>
          </p>
        </div>
        <p className="year">© {year} Draupnir Bio</p>
      </div>
      <motion.div
        initial={{ y: isMobile ? 0 : 500 }}
        animate={!isMobile ? { y: inView ? 0 : 500 } : { y: 0 }}
        transition={{ duration: 0.6, ease: ease.inOutSnap }}
        className="footer"
        style={{ marginLeft: marginSec }}
      >
        <div className="contact">
          <h3>{data.headline}</h3>
          <a href={`mailto:${data.email}`}>{data.email}</a>
        </div>
        <div className="right">
          <div className="logo">
            <Logo />
          </div>
          <div className="address">
            <RichText blocks={data._rawAddress} />
          </div>
          <div className="social">
            <p>Follow us:</p>
            <div className="links">
              {data.social.map((socialItem, i) => (
                <a
                  className="underline"
                  key={i}
                  href={socialItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {socialItem.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding-top: var(--sp-13);
  color: var(--red);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--white);
  margin-left: var(--sidebar-width);

  .disclaimer {
    order: 2;
    color: var(--black-light);
    font-size: var(--fs-small);
    display: flex;
    padding-bottom: var(--sp-3);
    padding-top: var(--sp-5);

    .terms {
      order: 2;
      position: absolute;
      padding-left: calc(18rem + var(--sp-1-3));

      .underline {
        &:after {
          background: ${(props) => props.color};
        }
      }
    }

    a {
      display: inline-block;
    }
  }

  .footer {
    background: var(--red-light);
    display: flex;
    flex-direction: column;
    padding-top: var(--sp-1-8);
    padding-bottom: calc(var(--sp-1-8) / 2);
    padding-left: var(--sp-1-3);
    padding-right: var(--sp-1-8);

    .contact {
      padding-bottom: var(--sp-3-5);
      display: flex;

      a {
        padding-left: 18rem;
        position: absolute;
        transition:all 350ms ease-out;
        &:hover {
          opacity:0.5;

        }
      }

      h3 {
        padding-bottom: var(--sp-1-2);
      }
    }

    .right {
      padding-top: var(--sp-3-5);
      width: 95%;

      .logo {
        display: none;
        width: var(--sp-13);
        padding-bottom: var(--sp-1-3);

        svg {
          width: 100%;
        }

        path {
          fill: var(--red);
        }
      }

      .address {
        font-size: var(--fs-small);
        padding-left: 18rem;

        p {
          margin:0 !important;
          white-space: nowrap;
          
        }
      }

      .social {
        font-size: var(--fs-small);
        display: flex;
        justify-content: space-between;
        padding-top: var(--sp-8-13);
        padding-bottom: var(--sp-1-3);
        padding-left: 18rem;

        p {
          opacity: 0.75;
        }

        .links {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
        }

        a {
          text-align: right;
          display: inline-block;
        }

        .underline {
          &:after {
            background: var(--red);
          }
        }
      }
    }
  }

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
  }

  @media ${media.L} {
    display: grid;

    .disclaimer {
      order: 0;
      grid-column: 2 / 5;
      flex-direction: column;
      justify-content: flex-end;

      .terms {
        order: 0;
        position: static;
        padding-left: 0;
      }

      .year {
        transform: translateY(0px);
      }

      a {
        align-self: flex-start;
      }
    }

    .footer {
      grid-column: 5 / 13;
      flex-direction: row;
      margin-right: -10rem;

      .contact {
        width: 50%;
        padding-bottom: 0;
        flex-direction: column;

        a {
          font-size: var(--fs-subhl);
          position: static;
          padding-left: 0;
        }
      }

      .right {
        border-top: 0;
        border-left: 1px solid rgba(255, 70, 40, 0.4);
        padding-top: 0;
        width: auto;

        > * {
          padding-left: var(--sp-2);
        }

        .logo {
          display: block;
          width: var(--sp-21);
        }

        .address {
          transform: translateY(5px);
          padding-left: var(--sp-2);
        }

        .social {
          padding-left: var(--sp-2);
          padding-bottom: 0;

          a {
            padding-left: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .footer {
      .contact {
        width: 60%;
      }
    }
  }

  @media (max-width: 400px) {
    .footer {
      .contact {
        a {
          padding-left: 13rem;
        }
      }

      .right {
        .address {
          padding-left: 13rem;
        }

        .social {
          padding-left: 13rem;
        }
      }
    }

    .disclaimer {
      .terms {
        padding-left: calc(13rem + var(--sp-1-3));
      }
    }
  }
`;

Footer.propTypes = {
  path: PropTypes.string,
};

export default Footer;
